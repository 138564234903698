.content-parabens {
  animation-name: bgContent;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  position: relative;
  .animation {
    width: 100vw;
    height: var(--height);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .dividir {
      display: flex;
      width: 75%;
      height: var(--height);
      animation-name: abrirColors;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      position: relative;
      @media only screen and (max-width: 600px) {
        animation-name: abrirColorsResponsive;
      }
      &:last-child {
        width: 25%;
        animation-name: abrirColorsColum2;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }
      .color {
        width: 100%;
        opacity: 0;
        transform: translateX(-100%);
        flex-basis: 100%;
        animation-name: slideAndScaleAnimation;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }
    }
    .content-home {
      position: absolute;
      width: 100vw;
      height: var(--height);
      display: flex;
      align-items: flex-start;
      padding-top: 2rem;
      justify-content: center;
      flex-wrap: wrap;
      z-index: 2;
      opacity: 0;
      animation-name: showContent;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      .header {
        padding-left: 20%;
        padding-right: 10%;
        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 36px;
          line-height: 35px;
          color: #f97a70;
          font-family: "BancoDoBrasilTitulosBold700";
        }
        h2 {
          font-family: "BancoDoBrasilTitulosBold700";
          font-size: 60px;
          line-height: 60px;
          color: #f97a70;
          margin-bottom: 0rem;
        }
        h3 {
          font-size: 18px;
          line-height: 26px;
          color: #f97a70;
          margin-top: 2rem;
        }
      }
      .person {
        position: absolute;
        bottom: -10px;
        width: 100%;
        // left: 0;
        img {
          width: 100%;
        }
      }
      @media only screen and (min-width: 600px) {
        display: flex;
        width: 100%;
        height: 100vh;
        flex-wrap: wrap;
        margin-top: 0rem;
        padding-top: 0rem;
        .header {
          display: flex;
          align-items: center;
          width: 40%;
          flex-wrap: wrap;
          height: 100vh;
          padding-left: 5%;
          padding-right: 0;
          h4 {
            font-size: 60px;
            width: 100%;
            line-height: 65px;
          }
          h3 {
            font-size: 30px;
            line-height: 54px;
          }
          h2 {
            font-size: 90px;
            width: 100%;
            line-height: 150px;
          }
        }
        .person {
          display: flex;
          width: 50%;
          position: relative;
          height: 100vh;
          bottom: 0;
          left: 0;
          top: 0;
          overflow: hidden;
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: flex-end;
          img {
            width: 100%;
            height: auto;
            // object-fit: scale-down;
            position: absolute;
            bottom: 0;
          }
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    animation-name: bottomAnime;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;
    .button-wrapper {
      position: relative;
      width: 80%;
      @media only screen and (min-width: 600px) {
        display: none;
      }
      button {
        cursor: pointer;
        border: 1px solid #f97a70;
        border-radius: 10px;
        background: #fcfc30;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #f97a70;
        padding: 0.7rem 2rem;
        width: 100%;
        font-family: "BancoDoBrasilTitulosBold700";
      }
      &::after {
        content: "";
        z-index: -1;
        position: absolute;
        width: 98%;
        height: 90%;
        background: #f97a70;
        border: 1px solid #fcfc30;
        border-radius: 10px;
        bottom: -3px;
        right: -3px;
      }
    }
    figure {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      img {
        height: 10vh;
      }
    }
    @media only screen and (min-width: 600px) {
      position: fixed;
      top: 2rem;
      right: 2rem;
      width: 25%;
    }
  }
}

@keyframes slideAndScaleAnimation {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    transform: scaleX(0.5);
    flex-basis: 100%;
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
    transform: scaleX(1);
    flex-basis: 40%;
  }
}

@keyframes abrirColors {
  0% {
    width: 75%;
  }
  100% {
    width: 20%;
    z-index: 3;
  }
}
@keyframes abrirColorsColum2 {
  0% {
    width: 25%;
  }
  100% {
    width: 6%;
    z-index: 1;
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
@keyframes bgContent {
  100% {
    background: #fcfc30;
  }
}
@keyframes bottomAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes abrirColorsResponsive {
  0% {
    width: 75%;
  }
  100% {
    width: 8%;
    z-index: 3;
  }
}

@keyframes abrirColorsColum2Responsivo {
  0% {
    width: 25%;
  }
  100% {
    width: 2%;
    z-index: 1;
  }
}

@media only screen and (min-width: 600px) {
  .content-parabens .animation .dividir {
    animation-name: abrirColorsResponsive;
  }
  .content-parabens .animation .dividir:last-child {
    animation-name: abrirColorsColum2Responsivo;
  }
}
