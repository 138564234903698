.page-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  font-family: Arial, sans-serif;
}

.login {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

table {
  width: 98%;
  margin-top: 20px;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
  button {
    margin-right: 1rem;
  }
  tr {
    td.tdrespostas {
      padding: 0;
      .respostas-quiz {
        display: flex;
        gap: 5px;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        &.active {
          max-height: 400px;
        }
        div {
          border: solid 1px black;
          padding: 10px;
        }
        h4 {
          margin-bottom: 1rem;
          font-weight: bold;
          font-family: "BancoDoBrasilTitulosBold700";
        }
        ul {
          li {
            // border: solid 1px red;
            padding: 10px;
            &.active {
              background: rgb(182, 255, 215);
            }
          }
        }
      }
    }
    &.print {
      td {
        background: #fff9b6;
      }
    }
    &.removed {
      td {
        background: #facdcd;
      }
    }
    &.print-removed {
      td {
        background: linear-gradient(180deg, #fff9b6, #facdcd);
      }

    }
  }
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

th {
  background-color: #007bff;
  color: #fff;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #e6e6e6;
}

tbody td:first-child {
  border-radius: 5px 0 0 5px;
}

tbody td:last-child {
  border-radius: 0 5px 5px 0;
}

.modal-imprimir {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .content-print {
    > .actions {
      position: absolute;
      right: 2rem;
      top: 2rem;
      button {
        padding: 10px;
        background-color: #007bff;
        color: #fff;
        font-size: 14px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-right: 2rem;
      }
    }
    width: 80%;
    padding: 2rem;
    background: #fff;
    flex-wrap: wrap;
    .card-print {
      width: 600px !important;
      .modelo-print {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        width: 600px !important;
        height: 450px !important;
        flex-direction: column;
        &:first-child {
          border-bottom: solid 1px black;
        }
        .numerocard {
          width: 100%;
          text-align: right;
          padding: 20px;
        }
        span {
          height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          font-size: 14pt;
          line-height: 18pt;
          font-family: "BancoDoBrasilTextosItalic";
        }
        .rodape {
          width: 100%;
          height: 80px;
          justify-content: space-between;
          display: flex;
          padding: 20px;
          span {
            height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-size: 10pt;
            line-height: 10pt;
            font-family: "BancoDoBrasilTitulosBold700";
            color: #465eff;
          }
          &.amarelo {
            span {
              color: #fcfc30;
            }
            svg {
              path {
                fill: #fcfc30;
                &:first-child {
                  fill: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modalLiberarCard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .content-modalLiberarcard {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    text-align: center;
    p {
      margin-bottom: 20px;
    }
  }
}



.action {
  display: flex;
  justify-content: center;
  gap: 10px;
}

button {
  padding: 10px 20px;
  background-color: #623f99;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #4c2e7a;
}


.relatorios {
  display: flex;
  padding: 3rem;
  gap: 2rem;
  .item {
    text-align: center;
    p {
      font-weight: bold;
      font-family: "BancoDoBrasilTitulosBold700";
      margin-bottom: 1rem;
    }
    span {
      color: #4c2e7a;
      font-family: "BancoDoBrasilTitulosBold700";
      font-size: 40px;
    }
  }
}