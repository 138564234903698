.teste-table{
  background: black;
  tr {
    td {
      height: 50px;
      border: solid 1px #c6c6c6;
      width: 60px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
    }
  }
}