.page-quiz {
  width: 100vw;
  height: var(--height);
  .bottom {
    position: absolute;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-height: 540px) {
      display: none;
    }
  }
}