:root {
  --border: #f97a70;
  --text: #f97a70;
  --bg: #fcfc30;
  --bgCard: #fcfc30;
  --bgbutton: #f97a70;
  --textButton: #fcfc30;
  --element01: #fcfc30;
  --element02: #05bf00;
  --bgPerguntaBox: #05bf00;
}

.step {
  background: var(--bg);
  width: 100vw;
  height: var(--height);
  position: relative;
  z-index: 1;
  .content-cardStep {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 6rem;
    height: var(--height);
    flex-wrap: wrap;
    @media only screen and (max-height: 600px) {
      align-items: flex-start;
      padding-top: 10px;
    }
    .controll-steps {
      padding: 0 2rem;
      padding-bottom: 1rem;
      .component-steps {
        p {
          font-size: 16px;
          line-height: 24px;
          font-family: "BancoDoBrasilTitulosBold700";
          color: #fff;
          small {
            font-family: "BancoDoBrasilTextos";
          }
        }
        .steps {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          margin-top: 1rem;
          .step-point {
            border: solid 1px #fcfc30;
            border-radius: 10px;
            height: 0.8rem;
            width: 100%;
            &.disabled {
              opacity: 0.4;
              background: transparent !important;
            }
          }
        }
        &.dark {
          p {
            color: #f97a70;
          }
          .steps {
            .step-point {
              border: solid 1px #f97a70;
            }
          }
        }
      }
    }
    .card-form {
      margin: 0 1rem 1rem 2rem;
      background: transparent;
      border: 2px solid var(--border);
      background: var(--bgCard);
      border-radius: 10px;
      padding: 2rem 1rem;
      position: relative;
      .form {
        h2 {
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          color: var(--text);
          font-family: "BancoDoBrasilTitulosBold700";
        }
        input,
        select,
        textarea {
          width: 100%;
          background: #ffffff;
          border: 1px solid #f97a70;
          border-radius: 10px;
          padding: 0.6rem 1rem;
          margin-bottom: 1rem;
          &::placeholder {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: rgba(249, 122, 112, 0.75);
            font-family: "BancoDoBrasilTitulosBold700";
          }
          @media only screen and (max-height: 600px) {
            margin-bottom: 5px;
          }
        }
        span.contador {
          display: block;
          padding-right: 10px;
          text-align: right;
          margin-top: -0.8rem;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: rgba(249, 122, 112, 0.75);
          font-family: "BancoDoBrasilTitulosBold700";
        }
        .option {
          margin-top: 1rem;
          label {
            display: flex;
            span {
              font-size: 16px;
              color: rgba(249, 122, 112, 0.75);
              line-height: 16px;
              display: block;
              a {
                font-size: 16px;
                color: rgba(249, 122, 112, 0.75);
                line-height: 16px;
              }
            }
            input {
              width: auto;
              margin-right: 10px;
            }
          }
          small {
            color: rgba(249, 122, 112, 0.75);
            font-size: 12px;
          }
        }
        hr {
          border: solid 1px rgba(249, 122, 112, 0.75) !important;
        }
        .quiz {
          h2 {
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            color: var(--text);
            font-family: "BancoDoBrasilTitulosBold700";
          }
          .respostas {
            > div {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              margin-bottom: 1rem;
              &.active {
                .select {
                  background: var(--border);
                  svg {
                    display: block;
                    path {
                      fill: var(--bg);
                    }
                  }
                }
              }
              span,
              p {
                font-family: "BancoDoBrasilTitulosBold700";
                font-size: 12px;
                line-height: 16px;
                color: var(--text);
              }
              .select {
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--border);
                border-radius: 100px;
                margin: 0 0.3rem;
                svg {
                  display: none;
                }
              }
              p {
                width: calc(100% - 40px);
              }
            }
          }
        }
        .action {
          display: flex;
          gap: 1rem;
          padding-top: 1rem;
          button {
            width: 100%;
            background: var(--bgbutton);
            border-radius: 10px;
            padding: 0.4rem;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var(--textButton);
            border: none;
            font-family: "BancoDoBrasilTitulosBold700";
            &:disabled {
              opacity: 0.4;
            }
          }
        }
      }
      &::after {
        content: "";
        z-index: -1;
        position: absolute;
        width: 97%;
        height: 97%;
        background: var(--border);
        border: 1px solid var(--border);
        border-radius: 10px;
        bottom: -7px;
        right: -7px;
      }
      &.close {
        animation-name: closeContent;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }
      &.quiz-Box {
        .form {
          .quiz {
            .respostas {
              > div {
                padding: 0.5rem;
                border: solid 1px var(--text);
                border-radius: 10px;
                &.active {
                  background: var(--bgPerguntaBox);
                  .select {
                    background: var(--bgPerguntaBox);
                    border: solid 1px var(--bg);
                    svg {
                      path {
                        fill: var(--bg);
                      }
                    }
                  }
                  p {
                    color: var(--bg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .controll-steps {
    width: 100%;
    &.close {
      animation-name: closeContent;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
  .animation-step {
    height: var(--height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    .element01 {
      height: var(--height);
      width: 0.7rem;
      background: var(--element01);
      animation-name: element01;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
    .element02 {
      height: var(--height);
      width: 0.7rem;
      background: var(--element02);
      animation-name: element02;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
    .revela {
      height: var(--height);
      width: calc(100vw - 0.14rem);
      background: #54dcfc;
      animation-name: revela;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
    &.close {
      .element01 {
        animation-name: elementFechar;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-delay: 0.3s;
      }
      .element02 {
        animation-name: elementFechar;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-delay: 0.2s;
      }
      .revela {
        animation-name: revelaFechar;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }
    }
  }
}

.step {
  // &.step-02 {
  //   background: #465eff;
  //   .animation-step {
  //     .element01 {
  //       background: #fcfc30;
  //     }
  //     .element02 {
  //       background: #05bf00;
  //     }
  //   }
  //   .content-cardStep {
  //     .card-form {
  //       background: #465eff;
  //       border: 2px solid #fff;
  //       &::after {
  //         background: #fff;
  //         border: 1px solid #fff;
  //       }
  //       .form {
  //         input {
  //           border-color: #fff;
  //           background: #465eff;
  //           &::placeholder {
  //             color: #fff;
  //           }
  //         }
  //         .action {
  //           button {
  //             background: #fff;
  //             color: #465eff;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // &.step-03 {
  //   background: #fcfc30;
  //   .animation-step {
  //     .element01 {
  //       background: #ff3131;
  //     }
  //     .element02 {
  //       background: #ffa10b;
  //     }
  //   }
  //   .content-cardStep {
  //     .card-form {
  //       background: #fcfc30;
  //       border: 2px solid #f97a70;
  //       &::after {
  //         background: #f97a70;
  //         border: 1px solid #f97a70;
  //       }
  //       .form {
  //         input {
  //           border-color: #f97a70;
  //           background: #fcfc30;
  //           &::placeholder {
  //             color: #f97a70;
  //           }
  //         }
  //         .action {
  //           button {
  //             background: #f97a70;
  //             color: #fcfc30;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

@keyframes element01 {
  0% {
    width: calc(100vw + 0.7rem);
    // opacity: 0;
  }
  100% {
    width: 0.7rem;
    // opacity: 1;
  }
}

@keyframes element02 {
  0% {
    width: calc(100vw + 0.7rem);
  }
  100% {
    width: 0.7rem;
  }
}
@keyframes revela {
  0% {
    width: calc(100vw - 0.14rem);
  }
  100% {
    width: 0;
  }
}

// animação para FECHAR STEP
@keyframes revelaFechar {
  0% {
    width: 0;
  }
  50% {
    width: calc(100vw + 0.7rem);
    margin-left: 0;
  }
  100% {
    margin-left: 100vw;
  }
}
@keyframes elementFechar {
  0% {
    width: 0.7rem;
  }
  50% {
    width: calc(150vw);
    margin-left: 0;
  }
  100% {
    width: calc(100vw + 0.7rem);
    margin-left: 100vw;
  }
}

@keyframes closeContent {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
