// font-family: 'Rubik', sans-serif;
// font-family: 'Rubik Mono One', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './assets/themes/colors.scss';
@import './assets/themes/app.scss';

.global-loading{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
}