.page-finish {
  padding: 3rem;
  background: #fcfc30;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media only screen and (min-width: 600px) {
    padding: 10rem;
  }
  h2 {
    font-size: 32px;
    line-height: 32px;
    color: #f97a70;
    @media only screen and (min-width: 600px) {
      text-align: center;
      width: 100%;
      br {
        display: none;
      }
    }
  }
  .content-finish {
    margin-bottom: 2rem;
    padding: 2rem 0.5rem;
    border: 2px solid #f97a70;
    border-radius: 10px;
    position: relative;
    background: #fcfc30;
    h2 {
      text-align: center;
      font-family: "BancoDoBrasilTitulosBold700";
    }
    p {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #f97a70;
    }
    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      width: 97%;
      height: 97%;
      background: #f97a70;
      border: 1px solid #f97a70;
      border-radius: 10px;
      bottom: -15px;
      right: -10px;
    }
  }

  .invesTalk,
  .palestras,
  .agende,
  .card,
  .brind,
  .finish {
    margin-bottom: 4rem;
    margin-top: 2rem;
    width: 100vw;
    padding-right: 6rem;
    overflow: hidden;
    @media only screen and (min-width: 600px) {
      width: auto;
      padding-right: 0;
    }
    img {
      width: 100%;
    }
    button {
      width: 100%;
      background: #f97a70;
      border-radius: 10px;
      padding: 0.4rem;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fcfc30;
      border: none;
      font-family: "BancoDoBrasilTitulosBold700";
      margin-top: 1rem;
      &:disabled {
        opacity: 0.4;
      }
    }
    a.button {
      background: #f97a70;
      border-radius: 10px;
      padding: 0.4rem;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fcfc30;
      border: none;
      font-family: "BancoDoBrasilTitulosBold700";
      margin-top: 1rem;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      text-decoration: none;
      justify-content: center;

      img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #f97a70;
      margin-bottom: 2rem;
      b {
        font-family: "BancoDoBrasilTitulosBold700";
      }
    }

    .item-carousel {
      padding-right: 2rem;
      p {
        text-align: left;
      }
    }
    .carousel-item-palestras {
      border: 1px solid #f97a70;
      border-radius: 10px;
      display: flex !important;
      position: relative;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 2rem;
      p {
        padding: 0;
        margin: 0;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #f97a70;
      }
      span {
        font-family: "BancoDoBrasilTitulosBold700";
        position: absolute;
        top: -1rem;
        background: #f97a70;
        border: 1px solid #fcfc30;
        border-radius: 10px;
        padding: 0.6rem 2rem;
        font-size: 13px;
        color: #fcfc30;
        width: 90%;
        text-align: center;
      }
    }
  }
  .playlist {
    h2 {
      font-size: 45px;
      line-height: 42px;
      color: #f97a70;
      font-family: "BancoDoBrasilTitulosBold700";
    }
    h3 {
      font-size: 32px;
      line-height: 32px;
      color: #f97a70;
      margin-top: -15px;
    }
    p {
      font-size: 23px;
      line-height: 30px;
    }
    a {
      background: #f97a70;
      border-radius: 10px;
      padding: 0.4rem;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #fcfc30;
      border: none;
      font-family: "BancoDoBrasilTitulosBold700";
      margin-top: 1rem;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      text-decoration: none;
      justify-content: center;

      img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.slick-list {
  overflow: visible !important;
  .slick-track {
    overflow: a;
    > div {
      padding-right: 3rem;
    }
  }
}

@media only screen and (min-width: 600px) {
  .agende,
  .card,
  .brind,
  .finish {
    width: 60% !important;
    margin: 0 auto;
  }
}
