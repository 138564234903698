@font-face {
  font-family: "BancoDoBrasilTextosMedium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/BancoDoBrasilTextos-Medium.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "BancoDoBrasilTextosLight";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/BancoDoBrasilTextos-Light.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "BancoDoBrasilTextosLightItalic";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/BancoDoBrasilTitulos-LightIt.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "BancoDoBrasilTextosItalic";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/BancoDoBrasilTitulos-Italic.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "BancoDoBrasilTextos";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/BancoDoBrasilTitulos-Regular.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "BancoDoBrasilTitulosBold700";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/BancoDoBrasilTitulos-Bold.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "BancoDoBrasilExtraBold";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/BancoDoBrasilTextos-XBold.ttf"); /* IE9 Compat Modes */
}

* {
  outline: none;
  font-family: "BancoDoBrasilTextos";
}

:root {
  body {
    font-family: "BancoDoBrasilTextos";
    overflow-x: hidden;
  }
}
